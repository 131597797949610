import axios from 'axios';

const api = axios.create({
baseURL: '/',
  timeout: 10000, // 请求超时设定
});

export default {
  getStatus(data) {
    return api.post('/api/get_status', data);
  },
  login(data) {
    return api.post('/api/login', data);
  }
};
