<template>
  <div class="flex bg-gray-50 overflow-hidden">
    <!-- Sidebar -->
    <Sidebar 
      :loadingDashboard="loadingDashboard"
     />

    <!-- Main Content -->
    <div class="flex flex-wrap w-full h-full">
      <!-- Card -->
      <div 
        v-for="service_info in all_services_info" 
        :key="service_info.service_name"
        class="w-full sm:w-1/2 lg:w-1/4 h-1/4 animate__animated animate__flipInX"
      >
        <div class="max-w-sm rounded-lg bg-gray-100 text-left m-10">
          <div class="px-6 py-4">
            <a class="font-bold text-xl mb-2 flex text-gray-600 group" :href="service_info.link" target="_blank">
              <span class="group-hover:text-rose-500 transition-colors">{{ service_info.service_name }}</span>
              <i class="material-symbols--link text-gray-100 ml-1 group-hover:text-rose-500 transition-colors"></i>
            </a>
            <p class="text-gray-500 text-base">
              {{ service_info.description }}
            </p>
          </div>
          <div class="px-6 pt-4 pb-2">
            <span 
              v-for="tag in service_info.tags" 
              :key="tag"
              class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-500 mr-2 mb-2 hover:text-rose-500 hover:bg-rose-200 cursor-default"
            >
              #{{ tag }}
            </span>
          </div>
        </div>
      </div>
      
    </div>

  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';  // 导入 Sidebar 组件
import api from '../api'; // 导入封装好的API模块
import 'animate.css';

export default {
  name: "DashboardPage",
  data() {
    return {
      all_services_info: [],
      loadingDashboard: false,
    };
  },
  components: {
    Sidebar,  // 注册 Sidebar 组件
  },
  methods: {
    async loadStatus() {
      // loading 
      this.loadingDashboard = true;
      
      // get status
      var data = {
        token: this.$store.state.token
      }
      const response = await api.getStatus(data);
      
      if (response.data.result && response.data.data.length > 0) {
        if (response.data.data.length > 0) {
          this.all_services_info = response.data.data; // 更新数据
        } else {
          console.log("data length: 0");
          this.all_services_info = [];
          alert('未查询到相关数据');
        }
      } else {
        // logout
        this.$router.push({ name: 'LandingPage' });
      }
      this.loadingDashboard = false;
    },
  },
  mounted() {
    this.loadStatus();
    //animated duration
    document.documentElement.style.setProperty('--animate-duration', '1s');
  },
};
</script>

<style src="../style/icons.css">
.material-icons {
  font-size: 24px;
}

</style>
