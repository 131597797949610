<template>
    <div class="flex overflow-hidden">
      <!-- Sidebar -->
      <Sidebar />
  
      <!-- Main Content -->
      <div class="flex flex-wrap w-full bg-gray-50">
        <div class="flex-1 pt-20 pl-20 h-1/2 bg-gray-50 text-left">
          <!-- 作者姓名 -->
          <h1 
            class="text-4xl font-bold tracking-tighter text-gray-400 mb-5 cursor-pointer"
            @mouseover="showAnswer('name')"
            @mouseleave="hideAnswer('name')"
            >
            作者
            <span class="text-gray-50 ml-8" id="name" style="display: inline-block;">
              郑林啸
            </span>
          </h1>

          <!-- 日期 -->
          <h1 
            class="text-4xl font-bold tracking-tighter text-gray-400 mb-5 cursor-pointer"
            @mouseover="showAnswer('date')"
            @mouseleave="hideAnswer('date')"
            >
            发布日期
            <span class="text-gray-50 ml-8 animate__animated" id="date" style="display: inline-block;">
              2024/08/09
            </span>
          </h1>
        

        <!-- 作者邮箱 -->
        <h1 
          class="text-4xl font-bold tracking-tighter text-gray-400 mb-5 cursor-pointer"
          @mouseover="showAnswer('email')"
          @mouseleave="hideAnswer('email')"
          >
          联系方式
          <span class="text-gray-50 ml-8 animate__animated" id="email" style="display: inline-block;">
            zhenglinxiao@logbase.cn
          </span>
        </h1>
      
      </div>


        
      </div>
      
      
    </div>
  </template>
  
  <script>
  import Sidebar from '@/components/Sidebar.vue';  // 导入 Sidebar 组件
  import 'animate.css';
  
  export default {
    name: "AboutPage",
    components: {
      Sidebar,  // 注册 Sidebar 组件
    },
    methods: {
      showAnswer(item_id){
        var item = document.getElementById(item_id);
        //var gray_text = "text-gray-50";
        var rose_text = "text-rose-400";
        var gray_text = "text-gray-50";
        var class_animated = "animate__animated";
        var class_animated_action_in = "animate__fadeInRight";
        var class_animated_action_out = "animate__fadeOutRight";
        item.classList.add(class_animated);
        //初始默认是灰色字体（不可见）
        if (!item.classList.contains(class_animated_action_in)) { 
          // 移除 class "text-gray-50"
          if (item.classList.contains(gray_text)) {
            item.classList.remove(gray_text);
          }
          // 添加 class "text-rose-400"
          item.classList.add(rose_text);
          // animation
          if (!item.classList.contains(class_animated_action_in)) { // 增加进场动画
            item.classList.add(class_animated_action_in);
          }
          if (item.classList.contains(class_animated_action_out)) { // 移除出场动画
            item.classList.remove(class_animated_action_out);
          }
        }
      },
      hideAnswer(item_id) {
        var item = document.getElementById(item_id);
        //var gray_text = "text-gray-50";
        var class_animated = "animate__animated";
        var class_animated_action_in = "animate__fadeInRight";
        var class_animated_action_out = "animate__fadeOutRight";
        item.classList.add(class_animated);
        //初始默认是灰色字体（不可见）
        if (item.classList.contains(class_animated_action_in)) { 
          // animation
          if (!item.classList.contains(class_animated_action_out)) { // 增加出场动画
            item.classList.add(class_animated_action_out);
          }
          if (item.classList.contains(class_animated_action_in)) { // 移除进场动画
            item.classList.remove(class_animated_action_in);
          }
        }
      },
    },
    mounted() {
      document.documentElement.style.setProperty('--animate-duration', '.8s');
    }
  };
  </script>
  
  <style src="../style/icons.css">
  .material-icons {
    font-size: 24px;
  }
  
  </style>
  