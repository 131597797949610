<template>
  <aside 
    class="sticky top-0 h-screen w-56 bg-gray-100 text-gray-800 p-4"
    @mouseover="titleHover" 
    @mouseleave="titleLeave"
    >
    <div class="flex items-center mb-4 space-x-1">
      <span 
        class="text-lg font-bold cursor-default text-sky-800 hover:text-rose-500" 
        href="#" 
        id="title">
        logbase.cloud
      </span>
    </div>
    <nav class="space-y-2">
      <router-link to="/dashboard" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-gray-300 text-gray-700': isActive, 'hover:bg-gray-200 active:bg-gray-300 text-gray-500': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 material-symbols--list-alt text-gray-500" />
              <span class="text-sm font-medium">服务总览</span>
              <i v-if="loadingDashboard" class="icon--loading custom-spin text-gray-500 ml-2"></i>
          </div>
      </router-link>
      <router-link to="/status" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-gray-300 text-gray-700': isActive, 'hover:bg-gray-200 active:bg-gray-300 text-gray-500': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 pajamas--status-health text-gray-500" />
              <span class="text-sm font-medium">运行状态</span>
              <i v-if="loadingStatus" class="icon--loading custom-spin text-gray-500 ml-2"></i>
          </div>
      </router-link>
      <router-link to="/about" exact v-slot="{ href, navigate, isActive }">
          <div
              :class="['w-full flex items-center space-x-2 py-2 px-2 rounded-lg', { 'bg-gray-300 text-gray-700': isActive, 'hover:bg-gray-200 active:bg-gray-300 text-gray-500': !isActive }]"
              :href="href"
              @click="navigate"
          >
              <i class="w-4 h-4 material-symbols--more text-gray-500" />
              <span class="text-sm font-medium">关于</span>
          </div>
      </router-link>
    </nav>
  </aside>
</template>

<script>
import 'animate.css';

export default {
  name: "TheSidebar",
  props: {
    loadingDashboard: {
      type: Boolean,
      default: false
    },
    loadingStatus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    titleHover() {
      var title_element = document.getElementById("title");
      var class_animated = "animate__animated";
      var class_animated_action = "animate__pulse";
      title_element.classList.add(class_animated);
      title_element.classList.add(class_animated_action);
    },
    titleLeave() {
      var title_element = document.getElementById("title");
      //var class_animated = "animate__animated";
      var class_animated_action = "animate__pulse";
      //title_element.classList.remove(class_animated);
      title_element.classList.remove(class_animated_action);
    },
  },
  mounted() {
  }
  };
</script>
  
<style src="../style/icons.css">
.custom-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
