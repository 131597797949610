<template>
  <div class="flex flex-col items-center justify-center min-h-screen bg-gray-50">
    <div class="w-full sm:w-1/2 lg:w-1/4 max-w-xs rounded-lg bg-gray-50 animate__animated animate__flipInX">
      <div class=" text-left">
        <div class="px-6 py-2">
          <h2 class="font-bold text-2xl mb-4 mt-2 text-gray-600 hover:text-blue-500 transition-colors text-center">logbase.cloud</h2>
          <form @submit.prevent="handleSubmit">
            <div class="flex items-center mb-4">
              <input 
                class="text-gray-600 text-sm flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500" 
                id="account" 
                v-model="form.account"
                placeholder="账号"
                />
            </div>
  
            <div class="flex items-center mb-4">
              <input 
                class="text-gray-600 text-sm flex-1 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500" 
                id="password" 
                type="password" 
                v-model="form.password"
                placeholder="密码"
              />
            </div>
          </form>
          
        </div>
        <div class="px-6 text-center">
          <button 
            class="inline-flex items-center justify-center w-full h-10 px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white font-bold border-b-4 border-blue-700 hover:border-blue-500 rounded focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-sky-500"
            id="submit_button"
            @click="handleSubmit"
            >
            登&nbsp;录
          </button>
        </div>
      </div>
    </div>
  </div>
  <InfoModal 
    :isOpen="isModalOpen" 
    :title="modalTitle" 
    :content="modalContent" 
    @close="handleCloseModal"
  />
  </template>
  
<script>
import InfoModal from '@/components/InfoModal.vue';

export default {
  name: "LandingPage",
  components: {
    InfoModal, // 注册 InfoModal 组件
  },
  data() {
    return {
      form: {
        account: '',
        password: '',
      },
      isModalOpen: false,
    };
  },
  methods: {
    async handleSubmit() {
      var isEmpty = false;
      var button_element = document.getElementById('submit_button');

      // 检查空值
      for (const key in this.form) {
        var inputElement = document.getElementById(key);
        if (!this.form[key]) {
          inputElement.classList.add('border-red-500'); 
          isEmpty = true;
        } else {
          inputElement.classList.remove('border-red-500');
        }
      }

      if (isEmpty === false) {
        console.log('表单提交', this.form);
        button_element.disabled = true;
        button_element.innerHTML = `
<span>
  登录中
  <i aria-hidden="true" role="status" class="inline-block rounded-full animate-spin icon--loading align-middle" />
</span>`;

        // 调用 Vuex 的 login action
        const result = await this.$store.dispatch('login', {
          account: this.form.account,
          password: this.form.password,
        });

        if (result.success) {
          // 登录成功，跳转到 dashboard
          this.$router.push({ name: 'DashboardPage' });
        } else {
          // 显示错误信息
          this.showInfo('登录失败', result.error);
        }

        button_element.disabled = false;
        button_element.innerHTML = '登&nbsp;录';
      }
    },
    showInfo(title, content) {
      this.modalTitle=title;
      this.modalContent=content;
      this.openModal();
    },
    openModal() {
      this.isModalOpen = true;
    },
    handleCloseModal() {
      this.isModalOpen = false;
    },
  }
};
</script>

<style scoped>
/* 额外的样式可以在这里添加 */
</style>
