<template>
  <transition
    name="modal-fade"
    @before-enter="beforeEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40" @click="closeModal">
      <div id="modal_window" class="bg-gray-100 p-6 rounded-lg shadow-lg w-1/5 animate__animated animate__fadeInUp" @click.stop>
        <h2 class="text-lg mb-4 text-gray-600 font-bold text-left">
          <i class="material-symbols--note text-md" style="vertical-align: middle; line-height: 1;" />
          报告问题
        </h2>
        <div class="mb-4 text-left text-gray-500">
          <slot>
            服务<span class="inline-block bg-gray-500 bg-opacity-90 rounded-full text-sm text-gray-50 ml-1 mr-1 px-3 py-1">#{{ content }}</span>运行状态异常？
          </slot>
        </div>
        <div class="flex justify-end space-x-2">
          <button class="px-4 py-1.5 rounded bg-blue-600 text-gray-100 bg-opacity-90 hover:bg-blue-700 hover:text-gray-50">提交</button>
          <button @click="closeModal" class="px-4 py-1.5 rounded bg-gray-500 bg-opacity-90 text-gray-100 hover:bg-gray-600 hover:text-gray-50">关闭</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css';

export default {
  name: 'ReportIssueModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
      default: 'null',
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    beforeEnter(el) {
      el.classList.add('animate__fadeIn');
    },
    leave(el) {
      const modal_element = el.querySelector('#modal_window'); // 声明并获取 modal_element
      el.classList.remove('animate__fadeIn');
      el.classList.add('animate__fadeOut');
      
      if (modal_element) {
        modal_element.classList.remove('animate__fadeInUp');
        modal_element.classList.add('animate__fadeOutDown');
      }
    },
    afterLeave(el) {
      const modal_element = el.querySelector('#modal_window'); // 声明并获取 modal_element
      el.classList.remove('animate__fadeOut');
      
      if (modal_element) {
        modal_element.classList.remove('animate__fadeOutDown');
      }
    }
  }
};
</script>


<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
